import * as React from 'react';
import { Button } from 'baseui/button';
import { ButtonGroup } from 'baseui/button-group';
import { FormControl } from 'baseui/form-control';
import { Grid, Cell } from 'baseui/layout-grid';
import { Input, SIZE as InputSize } from 'baseui/input';
import { Search } from 'baseui/icon';
import { StyledLink } from 'baseui/link';

import { AddressT } from '../../../types';
import { useLetters, ErrorsT } from '../../../context/letters-context';
import { STATES } from '../../../constants';
import { useAuth } from '../../../context/auth-context';

type CustomerLookupProps = {
  onSubmit: () => void;
};

const CustomerLookup = (props: CustomerLookupProps) => {
  const { checkAuth } = useAuth();
  const { data, setData, formData, setFormData, errors, setErrors } = useLetters();

  const fetchOrder = async (event: React.FormEvent) => {
    event.preventDefault();

    const formErrors: ErrorsT = {};

    // TODO: abstract this required validation via props...
    if (!formData.orderId) {
      formErrors.orderId = 'Required';
    }

    if (Object.keys(formErrors as object).length < 1) {
      try {
        const user = checkAuth && (await checkAuth());

        const res = await fetch(`${process.env.REACT_APP_CW_API_ADMIN_URL}/orders/${formData?.orderId}/addresses/`, {
          method: 'GET',
          mode: 'cors',
          headers: { authorization: `Bearer ${user?.auth.idToken}` },
        });

        const json = await res.json();
        const {
          billing,
          shipping: [shipping],
        } = json as { billing: AddressT; shipping: AddressT[] };

        setData({
          ...data,
          customerEmail: billing.email,
          shippingAddress: shipping,
          billingAddress: billing,
        });

        // TODO: this logic is used in both CustomerLookup and SendMail
        const addressObj: AddressT = formData.addressType?.id === 'billing' ? billing : shipping;
        const state = STATES.find(state => state.id === addressObj?.state_or_province) || STATES[0];

        setFormData({
          ...formData,
          firstName: addressObj?.first_name || '',
          lastName: addressObj?.last_name || '',
          emailAddress: billing.email || '',
          addressLine1: addressObj?.address1 || '',
          addressLine2: addressObj?.address2 || '',
          city: addressObj?.city || '',
          state: [state],
          zip: addressObj?.postal_code || '',
        });

        handleSubmit();
      } catch (e) {
        formErrors.orderId = 'Invalid order number';
      }
    }

    setErrors({ ...formErrors });
  };

  const handleSubmit = () => {
    props.onSubmit();
  };

  return (
    <form method="POST" onSubmit={fetchOrder}>
      <Grid
        overrides={{
          Grid: {
            style: {
              justifyContent: 'center',
            },
          },
        }}
      >
        <Cell span={4}>
          <FormControl label="Customer Lookup" error={errors?.orderId || null}>
            <ButtonGroup>
              <Input
                startEnhancer={<Search size="26px" />}
                placeholder="Search by order number..."
                size={InputSize.large}
                value={formData.orderId}
                onChange={event => setFormData({ ...formData, orderId: event.currentTarget.value })}
                error={!!errors?.orderId}
              />
              <Button>Search</Button>
            </ButtonGroup>
          </FormControl>

          <p style={{ textAlign: 'center', fontStyle: 'italic' }}>
            or <StyledLink onClick={handleSubmit}>skip this step</StyledLink>
          </p>
        </Cell>
      </Grid>
    </form>
  );
};

export default CustomerLookup;
