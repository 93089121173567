import type { BarcodeDetectorPolyfill } from '@undecaf/barcode-detector-polyfill';
import type * as PDFjs from 'pdfjs-dist';
import { getTracking } from 'ts-tracking-number';
import { parseJson } from '.';
import { ReturnLabelT } from '../modules/return-authorization/types';

declare global {
  interface Window {
    BarcodeDetector: typeof BarcodeDetectorPolyfill;
    'pdfjs-dist/build/pdf': typeof PDFjs;
  }
}

export const verifyTrackingNumbers = async (
  files: File[],
  returnId: string,
  abortController: AbortController
): Promise<ReturnLabelT[]> => {
  if (!('BarcodeDetector' in window)) {
    console.log('Barcode Detector is not supported by this browser.');
    return [];
  } else {
    console.log('Barcode Detector supported!');

    const pdfJS = window['pdfjs-dist/build/pdf'];
    pdfJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfJS.version}/pdf.worker.js`;
    const reader = new window.BarcodeDetector();

    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d', { alpha: false, willReadFrequently: true });

    const formData = new FormData();
    let decodedLabels: ReturnLabelT[] = [];
    let extractedLabels: ReturnLabelT[] = [];

    for (let file of files) {
      const pdf = await pdfJS.getDocument({ data: await file.arrayBuffer(), worker: new pdfJS.PDFWorker() }).promise;
      let decodedLabel: ReturnLabelT = { file: file.name, filename: file.name, tracking: [] };

      for (let index of Array(pdf.numPages).keys()) {
        const page = await pdf.getPage(index + 1);
        const viewport = page.getViewport({ scale: 4 });

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        if (!canvasContext) {
          console.log('error getting canvas context');
          return [];
        } else {
          await page.render({ canvasContext, viewport }).promise;
          const barcodes = await reader.detect(canvas);

          for (let barcode of barcodes) {
            const res = getTracking(barcode.rawValue);
            if (res) {
              decodedLabel.tracking.push({ carrier: res.courier.name, number: res.trackingNumber });
            }
          }
        }
      }

      if (decodedLabel.tracking.length) {
        decodedLabels.push(decodedLabel);
        formData.append(`return-label-to-upload`, file);
      } else {
        formData.append(`return-label-to-extract`, file);
      }
    }

    const resp = await fetch(`${process.env.REACT_APP_CW_API_ADMIN_URL}/returns/${returnId}/tracking/verify/`, {
      signal: abortController.signal,
      method: 'POST',
      body: formData,
    });
    const verifyRes = await parseJson(resp);

    if (verifyRes.validTracking) {
      extractedLabels = verifyRes.validTracking.filter((r: ReturnLabelT) => r !== null);
    }
    if (verifyRes.validUploads) {
      for (let label of decodedLabels) {
        const uploaded = (verifyRes.validUploads as ReturnLabelT[]).find(l => l.file === label.file);
        if (uploaded) {
          label.file = uploaded.file;
          label.filename = uploaded.filename;
        }
      }
    }

    return [...decodedLabels, ...extractedLabels];
  }
};
