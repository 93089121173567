import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { parseJson } from '../../utils';
import SearchReturnPOFormBody from './SearchReturnPOFormBody';
import { ReturnDataT } from './types';
import { ReturnLineItemT } from './types';

const defaultValues = {
  return_id: '',
};

const SearchReturnPOForm = ({
  returnId,
  onSearchSubmit,
}: {
  returnId: string;
  onSearchSubmit: (data: any) => void;
}) => {
  const methods = useForm({ defaultValues });

  React.useEffect(() => {
    if (returnId) {
      methods.reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnId]);

  const handleSearchSubmit = async (data: any) => {
    try {
      const resp = await fetch(`${process.env.REACT_APP_CW_API_ADMIN_URL}/returns/authorization/${data.return_id}/`);
      const json = await parseJson(resp);

      if (!resp.ok) {
        throw new Error(json?.msg);
      }

      const returnLineItems: ReturnLineItemT[] = [];

      for (let lineItem of json.lineItems) {
        returnLineItems.push({
          record_id: lineItem.id,
          cw_sku: lineItem.sku,
          return_quantity: lineItem.returnQty,
          vendor_id: lineItem.vendorId,
          vendor_uom: lineItem.vendorUOM,
        });
      }

      const returnData: ReturnDataT = {
        record_id: json.id,
        return_id: json.returnId,
        rr_canceled: json.rrCancelled,
        line_items: returnLineItems,
      };

      onSearchSubmit(returnData);
    } catch (error: any) {
      methods.setError('return_id', {
        message: error.message || 'Something went wrong, please try again',
      });
      return false;
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSearchSubmit)}>
          <SearchReturnPOFormBody />
        </form>
      </FormProvider>
    </>
  );
};

export default SearchReturnPOForm;
