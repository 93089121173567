import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { ERROR_MESSAGE } from '../../../constants';
import { useAuth } from '../../../context/auth-context';
import { parseJson } from '../../../utils';
import ReturnRequestError from '../ReturnRequestError';
import type { OrderDataT } from '../types';
import ReturnRequestFormBody from './ReturnRequestFormBody';

const ReturnRequestForm = ({
  orderData,
  onReturnSubmit,
}: {
  orderData: OrderDataT;
  onReturnSubmit: (data: any) => void;
}) => {
  const { user } = useAuth();

  const defaultValues = {
    global: null,
    return_full: false,
    total_label_count: [],
    line_item: orderData.line_items.map(() => ({
      return_quantity: 0,
      reason_code: [],
      refund_type: [],
    })),
    label_count: orderData.label_details.map(() => []),
  };

  const methods = useForm({ mode: 'onBlur', defaultValues });

  const [orderNumber, setOrderNumber] = React.useState(orderData.order_number);

  React.useEffect(() => {
    if (methods.formState.isValidating) {
      methods.clearErrors('global');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState]);

  React.useEffect(() => {
    if (orderData.order_number !== orderNumber || methods.formState.isSubmitSuccessful) {
      methods.reset(defaultValues);
      setOrderNumber(orderData.order_number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData.order_number, methods.formState, orderNumber]);

  const handleReturnSubmit = async (data: any) => {
    try {
      const resp = await fetch(`${process.env.REACT_APP_CW_API_ADMIN_URL}/returns/request/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user?.auth.idToken}`
        },
        body: JSON.stringify({
          orderId: orderNumber,
          carewellCSR: user?.email,
          notes: data.cw_user_notes,
          automationDisabled: data.automation_disabled || false,
          labelDetails: Object.entries(data.label_count).map(([key, value]: any[]) => {
            const origValue = orderData.label_details[key];
            return {
              labelCount: parseInt(value[0]?.id),
              name: origValue.name,
            };
          }),
          lineItems: Object.entries(data.line_item).map(([key, value]: any[]) => {
            const origValue = orderData.line_items[key];
            return {
              purchaseOrderLabel: origValue?.purchase_order_label,
              uom: origValue?.vendor_uom,
              sku: origValue?.cw_sku,
              returnQty: parseInt(value.return_quantity),
              reasonCode: value.reason_code?.[0]?.id,
              refundType: value.refund_type?.[0]?.id,
            };
          }),
        }),
      });
      const json = await parseJson(resp);

      if (!resp.ok) {
        throw new Error(json?.msg);
      }

      onReturnSubmit({
        ...json,
        order_number: orderData.order_number,
      });
    } catch (error: any) {
      methods.setError('global', {
        message: error.message || ERROR_MESSAGE.generic,
      });
      return false;
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleReturnSubmit)}>
          <ReturnRequestError errorMessage={methods.formState.errors.global?.message} />
          <ReturnRequestFormBody data={orderData} />
        </form>
      </FormProvider>
    </>
  );
};

export default ReturnRequestForm;
