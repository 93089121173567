import React, { useState } from 'react'
import { Button } from 'baseui/button'
import { FormControl } from 'baseui/form-control'
import { Grid, Cell } from 'baseui/layout-grid'
import { H1 } from 'baseui/typography'
import { Input } from 'baseui/input'
import { Combobox } from 'baseui/combobox'
import { Tabs, Tab } from 'baseui/tabs-motion'
import { ListItem, ListItemLabel } from 'baseui/list'
import { Check, Menu } from 'baseui/icon'

import { Card, StyledBody } from 'baseui/card'

type OptionT = { label: string; id: string }
const options: OptionT[] = [
  { label: 'Order Receipt (Email)', id: 'receipt' },
  { label: 'Shipping Notice (Email)', id: 'shipped' },
]

const Notifications = () => {
  const [step, setStep] = useState(1)
  const [orderId, setOrderId] = useState('')
  const [recipient, setRecipient] = useState('')
  const [type, setType] = useState<OptionT>()
  // const [result, setResult] = useState('')
  const [activeKey, setActiveKey] = useState<React.Key>(0)

  async function callTheAPI(event: any) {
    event.preventDefault()

    const mapping: any = {
      shipped: 'send-shipping-notification',
      receipt: 'send-order-receipt',
    }

    let payload = {
      order_id: orderId,
      scope: type?.id ? mapping[type?.id] : '',
      template: {},
      recipient: recipient !== '' ? recipient : '',
    }

    const res = await fetch(
      `${process.env.REACT_APP_EVENTS_URL}/events/orders/${type?.id}/`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }
    )

    // const response = await res.json()
    // setResult(JSON.stringify(response, null, 2))

    if (res.ok) {
      setStep(2)
    } else {
      setStep(3)
    }
  }

  return (
    <>
      <Grid>
        <Cell span={12}>
          <H1>Notifications</H1>
          <p>Send common customer communications via email.</p>
        </Cell>
      </Grid>
      <Tabs
        activeKey={activeKey}
        onChange={({ activeKey }) => setActiveKey(activeKey)}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              margin: `0 auto`,
              maxWidth: `1280px`,
            }),
          },
        }}
      >
        <Tab title='Send Notifications' artwork={Check}>
          <form>
            <Card
              overrides={{
                Body: {
                  style: ({ $theme }) => ({
                    padding: `2rem 0`,
                    margin: `1rem 0`,
                  }),
                },
              }}
            >
              <StyledBody>
                <Grid>
                  <Cell span={3}>
                    <FormControl label={() => 'Order Number'}>
                      <Input
                        value={orderId}
                        onChange={(event) =>
                          setOrderId(event.currentTarget.value)
                        }
                      />
                    </FormControl>
                  </Cell>
                  <Cell span={6}>
                    <FormControl
                      label={() => 'Recipient Address'}
                      caption={() =>
                        'Optional. Overrides email address from order.'
                      }
                    >
                      <Input
                        value={recipient}
                        placeholder='user@email.com'
                        onChange={(event) =>
                          setRecipient(event.currentTarget.value)
                        }
                      />
                    </FormControl>
                  </Cell>
                  <Cell span={3}>
                    <FormControl label={() => 'Message Type'}>
                      <Combobox
                        value={type?.label || ''}
                        onChange={(_, option) => setType(option as OptionT)}
                        mapOptionToString={(option) => option.label}
                        options={options}
                        name='filtered-options'
                      />
                    </FormControl>
                  </Cell>
                  <Cell span={3}>
                    <Button onClick={callTheAPI}>Send Notification</Button>
                  </Cell>
                  <Cell span={9}>
                    {step === 1 ? <p></p> : null}
                    {step === 2 ? (
                      <>
                        <p>
                          <strong>Success!</strong> Your request to send the{' '}
                          {type?.label} notification has been completed for
                          Order ID: {orderId}
                        </p>
                      </>
                    ) : null}
                    {step === 3 ? (
                      <>
                        <p>
                          <strong>Failed!</strong> Your request to send the{' '}
                          {type?.label} notification was unsuccessful for Order
                          ID: {orderId}
                        </p>
                      </>
                    ) : null}
                  </Cell>
                </Grid>
              </StyledBody>
            </Card>
          </form>
        </Tab>
        {/* @ts-ignore */}
        <Tab title='Notification History' disabled></Tab>
        {/* @ts-ignore */}
        <Tab title='Notification Preferences' disabled></Tab>
        <Tab title='Related Resources' artwork={Menu}>
          <ListItem
            sublist
            endEnhancer={() => (
              <Button
                $as='a'
                href='https://postmarkapp.com/'
                target='_blank'
                size='compact'
                kind='primary'
              >
                Login
              </Button>
            )}
          >
            <ListItemLabel>
              <strong>Postmark</strong>- Customer-facing emails including
              Receipts, Shipping Notifications, etc.
            </ListItemLabel>
          </ListItem>
          <ListItem
            sublist
            endEnhancer={() => (
              <Button
                $as='a'
                href='https://dashboard.lob.com/#/login'
                target='_blank'
                size='compact'
                kind='primary'
              >
                Login
              </Button>
            )}
          >
            <ListItemLabel>
              <strong>LOB</strong> - Transactional postal mail delivery for
              Shipping Labels, etc. Also powers Address Verification.
            </ListItemLabel>
          </ListItem>
          <ListItem
            sublist
            endEnhancer={() => (
              <Button
                $as='a'
                href='https://app.heypoplar.com/users/sign_in'
                target='_blank'
                size='compact'
                kind='primary'
              >
                Login
              </Button>
            )}
          >
            <ListItemLabel>
              <strong>Poplar</strong> - Promotional postal mail, including
              Welcome Letters, Postcards, Discounts, etc.
            </ListItemLabel>
          </ListItem>
        </Tab>
      </Tabs>
    </>
  )
}

export default Notifications
